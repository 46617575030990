import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoES } from "../components/seo";
import { translations } from "../locales/birthday.js";
import EventsTypes from "../components/eventsTypes";
import background from "../assets/img/eventsTypes/birthday.svg";

const Birdthday = () => {
  return (
    <Layout page="birthday" language="es">
      <main className=" container">
        <EventsTypes
          background={background}
          text={translations.es.text}
          title={translations.es.title}
          textButton={translations.es.button}
          language={"es"}
        />
      </main>
    </Layout>
  );
};

export default Birdthday;

export const Head = () => (
  <SeoES
    title="Invitaciones digitales de cumpleaños Online | Crea invitaciones únicas con Kolibird"
    description="Celebra tu cumpleaños con invitaciones digitales personalizadas hechas en Kolibird. Olvídate del papel y envía tus invitaciones de manera fácil y ecológica. Regístrate ahora en Kolibird y comienza a diseñar tus invitaciones de cumpleaños únicas."
    hrefLangs={[
      {
        href: "https://kolibird.app/invitaciones-digitales-cumpleanos/",
        lang: "es",
      },
      {
        href: "https://kolibird.app/en/digital-birthday-invitations/",
        lang: "en",
      },
    ]}
  />
);
